<template>
  <v-container fluid>
    <!-- Title -->
    <v-row no-gutters>
      <v-col cols="12">
        <v-card color="transparent" flat dark>
          <div class="text-h4 font-weight-bold text-center" style="padding: 5vh">Exchange Rate</div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Body -->
    <v-row no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-card rounded="lg" width="90%" class="pa-2">
            <v-card-subtitle style="display: inline-block">Exchange Rate</v-card-subtitle>

            <v-card-text class="d-flex">
              <v-text-field class="input-box" v-model="rate" flat outlined solo dense single-line hide-details="auto" />
              <v-btn @click="$store.dispatch('updateExchangeRate')" class="text-capitalize ml-2" outlined>Update Rate</v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ExchangeRate',
  computed: {
    ...mapState(['exchangeRate']),
    rate: {
      get() {
        return this.exchangeRate.rate
      },
      set(value) {
        this.$store.commit('UPDATE_EXCHANGE_RATE', value)
      }
    }
  },
  created() {
    this.$store.dispatch('fetchExchangeRate')
  }
}
</script>

<style scoped>
.input-box {
  max-width: 200px;
}
</style>
